.main {
  background-color: #1E1338 !important;
  padding: 20px;
  min-height: 100vh;
}

.offerCardContainer {
  margin: 15px;
  display: flex;
  color: #ffffff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.offerCard {
  background-color: #ffffff !important;
  border: 1px solid #ffffff;
  color: black;
  display: flex;
  padding: 10px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  max-width: 400px;
  height: 500px;
  margin: 10px;
}

.hideOffers {
  display: none;
}

.buttonDiv {
  background-color: #522F91 !important;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.buttonDiv:hover {
  background-color: #1E1338!important;
  border: 1px solid #FFFFFF;
}

.logoutButton {
  background-color: black !important;
  border-radius: 0px;
  padding: 15px;
  margin: 10px 10px -10px 0;
  color: white;
}

.logoutButton:hover {
  background-color: #0a2d38 !important;
}

.navBar {
  position: fixed;
  z-index: 1;
  top: 0;
  display: flex;
  flex-direction: row;
  background-color: #303030;
  min-height: 50px;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.componentContainer {
  margin-top: 50px;
}

.burgerMenu {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 2%;
}

.homeIcon {
  display: flex;
  justify-content: center;
  width: 100%;
}

.videoIcon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 2%;
}
.userMenu {
  position: fixed;
  z-index: 1;
  padding: 10px;
  width: fit-content;
  background-color: #303030;
  height: fit-content;
  padding: 0px 25px;
}
.userMenuH3 {
  color: white !important;
  font-weight: normal;
}

.hideCheckout {
  display: none;
}

.allComponentsContainer {
  display: flex;
  flex-direction: row;
}

.componentColumnContainer {
  margin: 10px;
  text-align: center;
  width: 50%;
  justify-content: center;
}

@media screen and (max-width: 796px) {
  .offerCardContainer {
    flex-direction: column;
    justify-content: center;
  }
  .allComponentsContainer {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .componentColumnContainer {
    width: 100%;
  }
}

.whiteBackground {
  background-color: #f8fafd !important;
}
